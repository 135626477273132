import React from 'react';
import Layout from '../../layouts/Project';
import ProjectIntro from '../../content/ProjectIntro';
import CapsAndTech from '../../content/CapsAndTech';
import Text from '../../content/Text';
import Caption from '../../content/ImageCaption';
import { StaticImage as Image } from 'gatsby-plugin-image';
import Images from '../../content/Images';
import Video from '../../content/Video';
import Press from '../../content/Press';
import Credits from '../../content/Credits';

export const frontmatter = {
  title: 'LA River',
  projectType: 'Experiences',
  brief: 'Discover the LA River',
  description:
    'LA River is an immersive Augmented Reality mobile experience that invites people to learn about the history, complexities and potential of the Los Angeles River system.',
  client: [
    'Sponsored by Verizon Media / RYOT Studios',
    'In Collaboration with Vrai Pictures'
  ],
  release: '2019',
  capabilities: [
    'Experience Design',
    'Product Development',
    'Real Time',
    'AR, VR',
    'CG & VFX',
    'Photogrammetry'
  ],
  technology: ['Unity', '  GLSL', '  Object Tracking', '  Digital Fabrication'],
  credits: {
    Client: 'Verizon Media, RYOT',
    Partnerships: 'Vrai Pictures, River LA, Gehry Partners, Olin, Geosyntec',
    'Graphic Design': 'What? Studio',
    'VFX / CG': 'The Endless Co., Superbright',
    'Sound Design': 'Antfood'
  },
  urls: {
    'apps.apple.com/LA-River-AR':
      'https://apps.apple.com/us/app/la-river-ar/id1502458666'
  },
  order: 2
};

const clientLogos = [
  <Image
    src="../../images/work/la-river/verizon-media.png"
    alt="Verizon Media"
    placeholder="none"
  />,
  <Image
    src="../../images/work/la-river/ryot-studio.png"
    alt="Ryot Studio"
    placeholder="none"
  />,
  <Image
    src="../../images/work/la-river/vrai-pictures.png"
    alt="Ryot Studio"
    placeholder="none"
  />
];
const Page = () => (
  <Layout title={frontmatter.title}>
    <ProjectIntro frontmatter={frontmatter} clientLogos={clientLogos} />{' '}
    <Images>
      <Image src="../../images/work/la-river/LA_topo_wood.jpg" alt="LA River" />
      <Caption>Our CNC model of the LA geography, based on GIS data</Caption>
    </Images>
    <CapsAndTech frontmatter={frontmatter} />
    <Text title="Opportunity" split={1} large>
      <p>
        In LA, a number of organizations are working towards a master plan that
        will help guide the future development of the Los Angeles River. This
        resulted in an immense media campaign around the future of Los Angeles,
        which has often been guided by misinformation rather than applicable
        data.
      </p>
      <p>
        Research around the master plan generated a highly technical set of
        documents by landscape architecture and government agencies, mapped to
        the basin of the LA River using the Geographic Information Systems (GIS)
        format.
      </p>
      <p>
        Translating this information into a format that can be understood by the
        public became an important mission for both the architects working on
        the project, as well as the non-profits on the ground working with the
        public.
      </p>
    </Text>
    <Video vimeoId="597432562" vimeoCode="b45f2f77a5" />
    <Text title="Solution">
      <p>
        Cartographic interfaces are a natural fit for the medium of Augmented
        Reality, where a map can be explored from multiple angles in 3D as a
        cinematic experience, putting the user in the position of the explorer
        or the cameraman.
      </p>
      <p>
        Superbright collaborated with both Gehry Partners and Olin Architects
        via the non-profit River LA to translate the existing research and GIS
        data into an interactive map of the Los Angeles river, where users can
        explore multiple stories to help you learn more about the river – its
        history, its purpose, and its potential using Augmented Reality.
      </p>
    </Text>
    <Images>
      <Image
        src="../../images/work/la-river/IMG_0024.PNG"
        alt="LA River topography detail"
      />
      <Image
        src="../../images/work/la-river/LA_iPHONE_003.png"
        alt="LA River topography"
      />
      <Image
        src="../../images/work/la-river/Render_LA_Topo_Paper_02.jpg"
        alt="LA River topography"
      />
    </Images>
    <Text title="Output" split={5}>
      <p>
        The twenty minute Augmented Reality experience was made available in two
        distinct versions:
      </p>
      <p>
        A bilingual table-top based mobile application released on the App
        Store, prioritising Spanish language first and then English, that is
        designed for home users.
      </p>
      <p>
        An installation version that maps the Augmented Reality experience onto
        a CNC machined model of the surrounding LA geography. This formed part
        of a travelling exhibition which first premiered at Summit LA in 2019
        and has since toured around the U.S.
      </p>
      <p>
        “This is an interactive, immersive experience, created to provide
        community access to the information driving Los Angeles River
        revitalization efforts in Los Angeles County and show the history,
        present-day impacts and potential solutions the LA River can provide
        communities” Verizon Media Center
      </p>
      <p>
        The documentary features 4 stories, History, Water, Nature and People.
      </p>
      <h3>Water - Agua</h3>
      <p>
        At 51 miles long, the LA River carries rainfall and runoff from 834
        square miles of urban and mountainous landscape out to the Pacific Ocean
        in Long Beach. River flow can jump from just a trickle to a raging
        torrent in just a few hours.
      </p>
      <h3>Nature - Naturaleza</h3>
      <p>
        At the moment, the LA River has a primary function as a flood-control
        channel, but many other challenges exist in the communities and natural
        habitats that surround it. The River represents an opportunity to
        re-establish a natural corridor, improve public health, and increase
        overall quality of life.
      </p>
      <h3>People - Personas</h3>
      <p>
        The LA River runs across a large percentage of Los Angeles County. It
        passes through 17 different cities, where a wide variety of languages
        are spoken, religions are practiced, and ethnicities coexist.
      </p>
    </Text>
    <Images>
      <Image src="../../images/work/la-river/914A4252.jpg" alt="LA River" />
      <Caption>Installation at Summit LA 2019</Caption>
      <Image src="../../images/work/la-river/914A4151.jpg" alt="LA River" />
      <Caption>Installation at Summit LA 2019</Caption>
      <Image src="../../images/work/la-river/914A4164.jpg" alt="LA River" />
      <Caption>Installation at Summit LA 2019</Caption>
      <Image
        src="../../images/work/la-river/LTpK667k4oizMB7.jpg"
        alt="LA River"
      />
      <Caption>Installation at Summit LA 2019</Caption>
      <Image
        src="../../images/work/la-river/LA_RIVER_LA_TIMES.png"
        alt="LA River"
      />
    </Images>
    <Press
      quotes={[
        {
          quote:
            'The LA River AR Platform invites the public into the future through their familiar mobile device, allowing them to experience firsthand how technology can be a meaningful and efficient engine to drive important societal changes taking place in their own backyard.',
          heading: 'Jake Sally',
          subheading: 'Head of Development at Verizon Media’s RYOT'
        },
        {
          quote:
            'The Los Angeles River as you’ve never seen it – in augmented reality',
          heading: 'Los Angeles Times',
          url: '​​https://www.latimes.com/entertainment-arts/story/2020-07-30/los-angeles-river-augumented-reality-history-app',
          date: 'July, 2020'
        },
        {
          quote:
            'An interactive, immersive experience, created to provide community access to the information driving Los Angeles River revitalization efforts in Los Angeles County and show the history, present-day impacts and potential solutions the LA River can provide communities',
          heading: 'Verizon Media Center',
          url: 'https://www.verizon.com/about/news/verizon-media-ryot-la-river-ar-platform',
          date: 'July, 2020'
        }
      ]}
    />
    <Credits credits={frontmatter.credits} />
  </Layout>
);

export default Page;
